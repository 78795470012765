import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import { GetConsignment, GetConsignment_GetConsignment } from '@/generated/GetConsignment'
import { CONSIGNMENT_MODEL_FRAGMENT } from '@/modules/graphql/ConsignmentModelFragment'
import {ConsignmentUpdateModel} from "@/generated/globalTypes";
import {UpdateConsignment_UpdateConsignment} from "@/generated/UpdateConsignment";
import {GetConsignmentAccessCode} from "@/generated/GetConsignmentAccessCode";

@Component
export default class ConsignmentService extends Vue {
  getConsignment(id: string): Promise<GetConsignment_GetConsignment> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.$route.query.accessCode ? this.getConsignmentQueryAccessCode : this.getConsignmentQuery,
          variables: {
            id: id
          }
        })
          .then((result: ApolloQueryResult<GetConsignment | GetConsignmentAccessCode>) => {
              //@ts-ignore
              resolve(this.$route.query.accessCode ? result.data.GetConsignmentAccessCode : result.data.GetConsignment)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  updateConsignment(updateConsignment: ConsignmentUpdateModel): Promise<UpdateConsignment_UpdateConsignment> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .mutate({
            mutation: this.updateConsignmentQuery,
            variables: {
                consignmentUpdateModel: updateConsignment
            }
          })
          .then(result => {
            resolve(result.data.updateShipment)
          })
          .catch(error => {
            reject(error)
          })
    })
  }

  private updateConsignmentQuery = gql`
    mutation UpdateConsignment($consignmentUpdateModel: ConsignmentUpdateModel!) {
      UpdateConsignment(a0: $consignmentUpdateModel) {
        id
      }
    }
  `

  private getConsignmentQuery = gql`
    ${CONSIGNMENT_MODEL_FRAGMENT}

    query GetConsignment($id: Int!) {
      GetConsignment(a0: $id) {
        ...ConsignmentModelFields
      }
    }
  `

  private getConsignmentQueryAccessCode = gql`
    ${CONSIGNMENT_MODEL_FRAGMENT}

    query GetConsignmentAccessCode($id: Int!) {
      GetConsignmentAccessCode(a0: $id) {
        ...ConsignmentModelFields
      }
    }
  `
}
